<template>
  <article v-if="repertoire" class="repertoires-card">
    <router-link class="repertoires-card__inner" :to="{ name: 'repertoire', params: { id: repertoire.id } }">
      <ImgComponent :img="repertoire.img" class="repertoires-card__image" />
      <div class="repertoires-card__blackout"></div>
      <div class="repertoires-card__content">
        <div v-if="repertoire.composer" class="repertoires-card__composer">
          <span>{{ repertoire.composer }}</span>
        </div>
        <div class="repertoires-card__text">
          <span v-if="repertoire.title" class="repertoires-card__title">{{ repertoire.title }}</span>
          <span v-if="repertoire.subtitle" class="repertoires-card__subtitle">
            {{ repertoire.subtitle }}
          </span>
        </div>
      </div>
    </router-link>
  </article>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "repertoiresCardComponent",
  props: {
    repertoire: Object,
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.repertoires-card {
  width 100%
  height 350px
  border-radius 5px
  overflow hidden

  &__inner {
    position relative
    display grid
    width 100%
    height 100%
  }

  &__image {
    absolute left top
    width 100%
    height 100%
  }

  &__blackout {
    absolute left top
    width 100%
    height 100%
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 52.08%, rgba(0, 0, 0, 0.82) 76.56%, #000000 100%);
    mix-blend-mode normal
    opacity 0.8
  }

  &__content {
    display flex
    flex-direction column
    align-items flex-start
    justify-content space-between
    z-index 1
    color var(--white)
    padding 28px 28px 25px
    +below(900px) {
      padding 18px 18px 15px
    }
  }

  &__composer {
    display flex
    align-items center
    padding 5px 15px
    border-radius 33px
    background var(--white_2)
    color var(--black)

    span {
      font-size .875em
      font-weight 500
      line-height 17px
    }
  }

  &__text {
    display grid
    grid-gap 10px
  }

  &__title {
    font-size 1.25rem
    font-weight 500
    line-height 30px
    +below(900px) {
      font-size 1.125rem
      line-height 1.625rem
    }
  }

  &__subtitle {
    font-size .875rem
    line-height 16px
    color var(--gray_2)
    +below(900px) {
      font-size .75rem
      line-height 1.375rem
    }
  }

  &__place {
    font-size .75rem
    line-height 16px
    color var(--gray_2)
  }
}
</style>
