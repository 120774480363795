<template>
  <main class="repertoires-page page">
    <BreadcrumbsComponent v-if="category && category.title" :title="category.title" />
    <div class="container-padding">
      <div v-if="repertoires && repertoires.length" class="repertoires-page__inner">
        <LoadingIndicator v-if="loading" title="Загрузка" />
        <div v-if="!loading" class="repertoires-page__content">
          <RepertoiresCardComponent
            v-for="(repertoire, i) in repertoires"
            :key="i"
            :repertoire="repertoire"
          />
        </div>
        <PaginationComponent
          :first="first"
          :page="page"
          :total="Math.ceil(total / first)"
          :totalItems="parseInt(total || '0')"
          @paginate="paginate"
        />
      </div>
      <h2 v-else class="no-info">В данный момент информация отсутствует</h2>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import RepertoiresCardComponent from "./components/RepertoiresCardComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import REPERTOIRES_PAGE from "gql/pages/RepertoiresPage.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "RepertoiresPage",
  metaInfo() {
    const meta = this.$store.state.repertoires.category_posters_item;
    return {
      title: meta?.title,
    };
  },
  async asyncData({ apollo, store, route }) {
    let variables = {
      page: 1,
      first: 6,
      category_id: route.query.category ? parseInt(route.query.category) : null,
    };
    await apollo.defaultClient
      .query({
        query: REPERTOIRES_PAGE,
        variables: variables,
      })
      .then(({ data }) => {
        store.dispatch("repertoires/save", data);
      });
  },
  data() {
    return {
      page: 1,
      first: 6,
      loading: false,
    };
  },
  computed: {
    repertoires() {
      return this.$store.state.repertoires.repertoires.data;
    },
    total() {
      return this.$store.state.repertoires.repertoires.paginatorInfo.total;
    },
    category() {
      return this.$store.state.repertoires.category_posters_item;
    },
  },
  methods: {
    paginate(e) {
      this.page = e;
      this.fetch();
    },
    fetch() {
      if (!this.loading) {
        this.loading = true;
        let variables = {
          page: this.page,
          first: this.first,
          category_id: this.$route.query.category ? parseInt(this.$route.query.category) : null,
        };
        this.$apollo
          .query({
            query: REPERTOIRES_PAGE,
            variables: variables,
          })
          .then(({ data }) => {
            this.$store.dispatch("repertoires/save", data);
            this.loading = false;
          });
      }
    },
  },
  components: {
    LoadingIndicator,
    BreadcrumbsComponent,
    RepertoiresCardComponent,
    PaginationComponent,
  },
};
</script>

<style lang="stylus">
.repertoires-page {

  &__inner {
    padding 50px 0 0 0
    display grid
    grid-gap 50px
  }

  &__content {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 30px 20px
    +below(900px) {
      grid-template-columns 1fr
    }
  }
}
</style>
